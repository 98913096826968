<script>
import CardUI from "@/components/UI/CardUI.vue";
import Empty from "@/components/Common/Empty.vue";
import EditorField from "@/components/Projects/Utils/EditorField.vue";
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import project_meets from "@/api/project_meets";

export default {
  name: "ProjectCalendarComponent",
  components: {ButtonUI, InputUI, DatePickerUIDecorator, EditorField, Empty, CardUI, DatePicker},
  methods: {
    async save() {
      this.loading = true;
      if (!this.editMeet.id) {
        await project_meets.create({...this.editMeet, project_id: this.$store.state.projects.currentProject.id})
      } else {
        await project_meets.update({...this.editMeet, project_id: this.$store.state.projects.currentProject.id})
      }
      await this.load();
      this.loading = false;
      this.createDialogOpen = false;
      this.clearDialog();

    },
    async remove(meet) {
      if(confirm('Удалить встречу?')) {
        await project_meets.delete({id: meet.id, project_id: this.project.id})
        await this.load();
      }
    },
    async load() {
      this.meets = [];
      this.meets = (await project_meets.all({id: this.$store.state.projects.currentProject.id})).data;
    },
    clearDialog() {
      this.editMeet = {
        title: null,
        duration: null,
        start_at: null,
        results: null,
        participants: null,
        place: null,
        agenda: null,
        id: null,
      }
    },
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },
  },

  async mounted() {
    await this.load();
  },
  data() {
    return {
      meets: [],
      createDialogOpen: false,
      loading: false,
      editMeet: {
        title: null,
        duration: null,
        start_at: null,
        results: null,
        participants: null,
        place: null,
        agenda: null,
        id: null,
      }
    }
  }
}
</script>

<template>
  <div>
    <v-dialog @click:outside="clearDialog" style="top: 100px !important;"
              v-model="createDialogOpen"
              max-width="600"
    >
      <CardUI :loading="false">
        <template slot="body">
          <v-card-title v-if="!editMeet.id" class="headline">
            Создание встречи
          </v-card-title>
          <v-card-title v-else class="headline">
            Редактирование встречи
          </v-card-title>
          <div class="pa-4">
            <h4 class="my-2">Название встречи</h4>
            <InputUI v-model="editMeet.title" counter dense outlined
                     placeholder="Встреча с заказчиком" prepend-icon="mdi-text"></InputUI>

            <h4 class="my-2">Дата встречи</h4>
            <DatePickerUIDecorator>
              <DatePicker format="DD.MM.YYYY HH:mm"
                          :range=false
                          value-type="YYYY-MM-DD HH:mm"
                          :show-second="false"
                          v-model="editMeet.start_at"
                          placeholder="Дата встречи"
                          type="datetime"></DatePicker>
            </DatePickerUIDecorator>


            <EditorField class="mt-3" v-model="editMeet.agenda" placeholder="Здесь можно писать"
                         description="Описание встречи, конкретные вопросы, которые будут обсуждаться на встрече"
                         label="Адженда встречи"/>
            <EditorField class="mt-3" v-model="editMeet.results" placeholder="Здесь можно писать"
                         description="Результаты встречи, заполняется после встречи"
                         label="Результаты встречи"/>

            <h4 class="my-2">Место встречи</h4>
            <InputUI v-model="editMeet.place" counter dense outlined
                     placeholder="У505" prepend-icon="mdi-text"></InputUI>

            <h4 class="my-2">Продолжительность встречи (в минутах)</h4>
            <InputUI v-model="editMeet.duration" counter dense outlined
                     min="0"
                     placeholder="60" type="number" prepend-icon="mdi-text"></InputUI>

            <h4 class="my-2">Участники встречи</h4>
            <InputUI v-model="editMeet.participants" counter dense outlined
                     placeholder="Команда проекта и заказчик" prepend-icon="mdi-text"></InputUI>

            <ButtonUI v-if="!editMeet.id" @click="save"
                      :disabled="!editMeet.participants || !editMeet.start_at || !editMeet.agenda || !editMeet.duration || !editMeet.place || !editMeet.title">
              Создать встречу
            </ButtonUI>
            <ButtonUI v-else @click="save"
                      :loading="loading"
                      :disabled="!editMeet.participants || !editMeet.start_at || !editMeet.agenda || !editMeet.duration || !editMeet.place || !editMeet.title">
              Сохранить
            </ButtonUI>
          </div>
        </template>
      </CardUI>
    </v-dialog>
    <v-sheet class="pa-4 mb-2" style="border-radius: 10px;">
      <h4 class="title">Календарь проекта
        <div style="cursor:pointer;" @click="createDialogOpen = true" class="float-right add-task">
          <v-icon>mdi-plus</v-icon>
        </div>

      </h4>
    </v-sheet>
    <Empty class="w-100" title="Пока нет встреч," description="но любой участник может создать встречу"
           v-if="!meets.length"/>
    <CardUI  class="mt-4"  v-for="meet in meets" :key="meet.id">
      <template slot="body">
        <h3>{{ meet.title }}
          <v-btn v-if="project.permissions.can_edit_info" @click="editMeet = meet; createDialogOpen = true" right style="float: right" small icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn @click="remove(meet)" right style="float: right" small icon>
            <v-icon color="red">
              mdi-cancel
            </v-icon>
          </v-btn>
        </h3>
        <div style="color: #797979">
          {{ $moment(meet.start_at).format('DD MMMM HH:mm') }}, {{ meet.duration }} минут · {{ meet.place }}
        </div>
        <h4 class="mt-4">Участники</h4>
        <p>{{ meet.participants }}</p>
        <EditorField :readonly="true"    :id="meet.id" class="mt-3" v-model="meet.agenda" placeholder="Здесь можно писать"
                     label="Адженда"/>
        <EditorField v-if="meet.results" :readonly="true" class="mt-3" v-model="meet.results"
                     placeholder="Здесь можно писать"
                     :id="meet.id"
                     :label="`Результаты`"/>

      </template>
    </CardUI>
  </div>
</template>

<style>
@media only screen and (min-width: 800px) {
  .v-dialog {
    margin-top: 100px;
  }
}
</style>
